a:link, a:visited {
  color: #5a3a9c;
  border-bottom: 2px solid #fca31100;
  text-decoration: none;
  transition: all .2s ease-in;
}

a:link:hover, a:visited:hover {
  border-bottom: 2px solid #fca311;
}

.INW9Ia_btn, a.INW9Ia_btn {
  color: #090615;
  cursor: pointer;
  background: #fca311;
  border: none;
  padding: 20px 40px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.INW9Ia_btn:hover, a.INW9Ia_btn:hover {
  color: #fff;
  background: #5a3a9c;
  border: none;
  box-shadow: 5px 5px #090615;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_btn, a.INW9Ia_btn {
    text-align: center;
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    display: block;
  }
}

.INW9Ia_pill {
  background: #fca311;
  border-radius: 20px;
  margin: 3px 0;
  padding: 6px 10px;
  font-size: .8em;
  display: inline-block;
}

.INW9Ia_pill:not(:last-child) {
  margin-right: 5px;
}

.INW9Ia_home {
  width: auto;
}

.INW9Ia_home .INW9Ia_launch {
  height: 600px;
  color: #fff;
  background: url("SpaceBg1.88c7ebb9.jpg") 0 0 / cover;
}

@media only screen and (max-width: 1000px) {
  .INW9Ia_home .INW9Ia_launch {
    height: 800px;
  }
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_launch {
    height: unset;
  }
}

.INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent {
    flex-direction: column;
  }
}

.INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent div {
  width: 50%;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent div {
    width: 90%;
  }
}

.INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent .INW9Ia_meteoric-text {
  color: #fca311;
}

.INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent img {
  width: 30%;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_launch .INW9Ia_innercontent img {
    display: none;
  }
}

.INW9Ia_home .INW9Ia_featured-case-study {
  margin: 30px 0;
  display: flex;
}

.INW9Ia_home .INW9Ia_featured-case-study img {
  width: 40%;
  height: auto;
  object-fit: cover;
}

.INW9Ia_home .INW9Ia_featured-case-study .INW9Ia_content {
  width: 60%;
  box-sizing: border-box;
  padding: 10%;
}

@media only screen and (max-width: 600px) {
  .INW9Ia_home .INW9Ia_featured-case-study {
    flex-direction: column;
  }

  .INW9Ia_home .INW9Ia_featured-case-study img, .INW9Ia_home .INW9Ia_featured-case-study .INW9Ia_content {
    width: 100%;
  }
}

.INW9Ia_home .INW9Ia_orgs {
  background-color: #e5e5e5;
  padding: 40px 0;
}

.INW9Ia_home .INW9Ia_orgs .INW9Ia_items {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .INW9Ia_home .INW9Ia_orgs .INW9Ia_items {
    flex-direction: column;
    justify-content: space-between;
  }
}

.INW9Ia_home .INW9Ia_orgs img {
  height: 120px;
  width: auto;
}

@media only screen and (max-width: 1000px) {
  .INW9Ia_home .INW9Ia_orgs img {
    height: unset;
    width: 65%;
  }

  .INW9Ia_home .INW9Ia_orgs img:not(:last-child) {
    margin-bottom: 10px;
  }
}

.INW9Ia_home .INW9Ia_mission-statement {
  color: #fff;
  text-align: center;
  background: #5a3a9c;
  padding: 20vh;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_mission-statement {
    padding: 3em;
  }
}

.INW9Ia_home .INW9Ia_mission-statement p {
  max-width: 600px;
  text-align: left;
  clear: both;
  margin: auto;
  font-size: 1.9em;
  font-weight: 700;
  display: block;
}

.INW9Ia_home .INW9Ia_mission-statement .INW9Ia_btn {
  margin-top: 30px;
  display: inline-block;
}

.INW9Ia_home .INW9Ia_what-we-do {
  color: #fff;
  background: #090615;
  padding: 3em 0;
}

@media only screen and (max-width: 800px) {
  .INW9Ia_home .INW9Ia_what-we-do {
    padding: 1em;
  }
}

.INW9Ia_home .INW9Ia_what-we-do h4 {
  margin-bottom: .5em;
}

.INW9Ia_home .INW9Ia_what-we-do ul {
  color: #ccc;
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  list-style: none;
}

.INW9Ia_home .INW9Ia_what-we-do ul li {
  padding: .2em 0;
}

.aTkW-q_videoplayer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.aTkW-q_videoplayer .aTkW-q_interactive_block {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.aTkW-q_videoplayer:after {
  content: "";
  padding-top: 56.25%;
  display: block;
}

.aTkW-q_videoplayer .aTkW-q_youtubeflexivid {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

a:link, a:visited {
  color: #5a3a9c;
  border-bottom: 2px solid #fca31100;
  text-decoration: none;
  transition: all .2s ease-in;
}

a:link:hover, a:visited:hover {
  border-bottom: 2px solid #fca311;
}

.xmx-kG_btn, a.xmx-kG_btn {
  color: #090615;
  cursor: pointer;
  background: #fca311;
  border: none;
  padding: 20px 40px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.xmx-kG_btn:hover, a.xmx-kG_btn:hover {
  color: #fff;
  background: #5a3a9c;
  border: none;
  box-shadow: 5px 5px #090615;
}

@media only screen and (max-width: 800px) {
  .xmx-kG_btn, a.xmx-kG_btn {
    text-align: center;
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    display: block;
  }
}

.xmx-kG_pill {
  background: #fca311;
  border-radius: 20px;
  margin: 3px 0;
  padding: 6px 10px;
  font-size: .8em;
  display: inline-block;
}

.xmx-kG_pill:not(:last-child) {
  margin-right: 5px;
}

.xmx-kG_call_to_action {
  background: #fff;
  padding: 5em 0;
}

.xmx-kG_call_to_action h3 {
  margin: 0;
}

.xmx-kG_call_to_action p {
  margin: 6px 0;
  font-size: 1.2em;
}

.xmx-kG_call_to_action .xmx-kG_btn {
  margin-top: 30px;
  display: inline-block;
}

nav.AJ9WsW_mobilenav {
  text-transform: uppercase;
  width: 100%;
  z-index: 999;
  background: #fff;
  font-weight: 700;
  display: none;
  position: absolute;
}

@media only screen and (max-width: 900px) {
  nav.AJ9WsW_mobilenav.AJ9WsW_open {
    flex-direction: column;
    display: flex;
  }
}

nav.AJ9WsW_mobilenav a:link, nav.AJ9WsW_mobilenav a:visited {
  height: 80px;
  border-bottom: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

nav.AJ9WsW_mobilenav a:hover {
  background: #e5e5e5;
  border-bottom: none;
}

nav.AJ9WsW_mobilenav.AJ9WsW_floating {
  padding-top: 60px;
}

nav.AJ9WsW_mobilenav.AJ9WsW_dark {
  background: #090615;
}

nav.AJ9WsW_mobilenav.AJ9WsW_dark a:link, nav.AJ9WsW_mobilenav.AJ9WsW_dark a:visited {
  color: #fff;
}

nav.AJ9WsW_mobilenav.AJ9WsW_dark a:hover {
  background: #090615;
}

.AJ9WsW_navigation_fill {
  height: 65px;
  display: none;
}

.AJ9WsW_navigation_fill.AJ9WsW_show {
  display: block;
}

.AJ9WsW_navigation.AJ9WsW_scrolled {
  width: 100%;
  z-index: 500;
  height: 65px;
  background: #ffffffe6;
  position: fixed;
  top: 0;
}

.AJ9WsW_navigation .AJ9WsW_wrapper {
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
}

.AJ9WsW_navigation .AJ9WsW_wrapper.AJ9WsW_static {
  position: relative;
}

.AJ9WsW_navigation nav.AJ9WsW_desktopnav {
  text-transform: uppercase;
  align-items: center;
  font-weight: 700;
  display: flex;
}

@media only screen and (max-width: 900px) {
  .AJ9WsW_navigation nav.AJ9WsW_desktopnav {
    display: none;
  }
}

.AJ9WsW_navigation a {
  margin: 0 15px;
  padding: 10px 0;
  display: inline-block;
}

.AJ9WsW_navigation a:link, .AJ9WsW_navigation a:visited {
  color: #fff;
  border-bottom: 2px solid #fff0;
  text-decoration: none;
}

.AJ9WsW_navigation a:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.AJ9WsW_navigation .AJ9WsW_logo {
  width: 200px;
  margin: 0;
  padding-left: 0;
}

.AJ9WsW_navigation .AJ9WsW_logo:link, .AJ9WsW_navigation .AJ9WsW_logo:visited, .AJ9WsW_navigation .AJ9WsW_logo:hover {
  border-bottom: none;
}

.AJ9WsW_navigation .AJ9WsW_logo img {
  width: 200px;
  filter: invert();
  -webkit-filter: invert();
  display: block;
}

.AJ9WsW_navigation.AJ9WsW_light a:link, .AJ9WsW_navigation.AJ9WsW_light a:visited {
  color: #090615;
  border-bottom: 2px solid #09061500;
}

.AJ9WsW_navigation.AJ9WsW_light a:hover {
  color: #090615;
  border-bottom: 2px solid #fca311;
}

.AJ9WsW_navigation.AJ9WsW_light a:link.AJ9WsW_active_menu_link, .AJ9WsW_navigation.AJ9WsW_light a:visited.AJ9WsW_active_menu_link {
  border-bottom: 2px solid #5a3a9c;
}

.AJ9WsW_navigation.AJ9WsW_light .AJ9WsW_logo:link, .AJ9WsW_navigation.AJ9WsW_light .AJ9WsW_logo:visited, .AJ9WsW_navigation.AJ9WsW_light .AJ9WsW_logo:hover {
  border-bottom: none;
}

.AJ9WsW_navigation.AJ9WsW_light .AJ9WsW_logo img {
  filter: unset;
  -webkit-filter: unset;
}

.AJ9WsW_navigation.AJ9WsW_light .AJ9WsW_mobile_btn span, .AJ9WsW_navigation.AJ9WsW_dark {
  background: #090615;
}

.AJ9WsW_navigation.AJ9WsW_dark a:link, .AJ9WsW_navigation.AJ9WsW_dark a:visited {
  color: #fff;
  border-bottom: 2px solid #fff0;
}

.AJ9WsW_navigation.AJ9WsW_dark a:hover {
  color: #fff;
  border-bottom: 2px solid #fca311;
}

.AJ9WsW_navigation.AJ9WsW_dark a:link.AJ9WsW_active_menu_link, .AJ9WsW_navigation.AJ9WsW_dark a:visited.AJ9WsW_active_menu_link {
  border-bottom: 2px solid #5a3a9c;
}

.AJ9WsW_navigation.AJ9WsW_dark .AJ9WsW_logo:link, .AJ9WsW_navigation.AJ9WsW_dark .AJ9WsW_logo:visited, .AJ9WsW_navigation.AJ9WsW_dark .AJ9WsW_logo:hover {
  border-bottom: none;
}

.AJ9WsW_navigation.AJ9WsW_dark .AJ9WsW_mobile_btn span {
  background: #fff;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn {
  width: 30px;
  height: 25px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -o-transition: .5s ease-in-out;
  cursor: pointer;
  margin: 5px;
  transition: all .5s ease-in-out;
  display: none;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .AJ9WsW_navigation .AJ9WsW_mobile_btn {
    display: inline-block;
  }
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn span {
  height: 3px;
  width: 100%;
  opacity: 1;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -o-transition: .25s ease-in-out;
  background: #fff;
  border-radius: 0;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn span:nth-child(1) {
  transform-origin: 0;
  top: 0;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn span:nth-child(2) {
  transform-origin: 0;
  top: 10px;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn span:nth-child(3) {
  transform-origin: 0;
  top: 20px;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn.AJ9WsW_open span:nth-child(1) {
  top: 0;
  left: 0;
  transform: rotate(45deg);
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn.AJ9WsW_open span:nth-child(2) {
  width: 0;
  opacity: 0;
}

.AJ9WsW_navigation .AJ9WsW_mobile_btn.AJ9WsW_open span:nth-child(3) {
  top: 21px;
  left: 0;
  transform: rotate(-45deg);
}

@media only screen and (max-width: 800px) {
  .AJ9WsW_navigation {
    height: auto;
  }
}

a:link, a:visited {
  color: #5a3a9c;
  border-bottom: 2px solid #fca31100;
  text-decoration: none;
  transition: all .2s ease-in;
}

a:link:hover, a:visited:hover {
  border-bottom: 2px solid #fca311;
}

.Io3xNG_btn, a.Io3xNG_btn {
  color: #090615;
  cursor: pointer;
  background: #fca311;
  border: none;
  padding: 20px 40px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.Io3xNG_btn:hover, a.Io3xNG_btn:hover {
  color: #fff;
  background: #5a3a9c;
  border: none;
  box-shadow: 5px 5px #090615;
}

@media only screen and (max-width: 800px) {
  .Io3xNG_btn, a.Io3xNG_btn {
    text-align: center;
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    display: block;
  }
}

.Io3xNG_pill {
  background: #fca311;
  border-radius: 20px;
  margin: 3px 0;
  padding: 6px 10px;
  font-size: .8em;
  display: inline-block;
}

.Io3xNG_pill:not(:last-child) {
  margin-right: 5px;
}

.Io3xNG_contact .Io3xNG_form_container {
  min-width: 400px;
}

.Io3xNG_contact .Io3xNG_contact_dark {
  color: #fff;
  background: #090615;
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
}

.Io3xNG_contact .Io3xNG_contact_dark .Io3xNG_instructions {
  width: 50%;
  font-size: 1.6em;
}

.Io3xNG_contact .Io3xNG_contact_dark input[type="text"], .Io3xNG_contact .Io3xNG_contact_dark input[type="email"], .Io3xNG_contact .Io3xNG_contact_dark select, .Io3xNG_contact .Io3xNG_contact_dark textarea {
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background: #363636;
  border: 2px solid #6a6a6a;
  padding: 1rem 1.25rem;
  font-size: 1em;
  transition: all .5s;
  display: block;
}

.Io3xNG_contact .Io3xNG_contact_dark input[type="text"]:focus, .Io3xNG_contact .Io3xNG_contact_dark input[type="email"]:focus, .Io3xNG_contact .Io3xNG_contact_dark select:focus, .Io3xNG_contact .Io3xNG_contact_dark textarea:focus {
  color: #090615;
  background: #fff;
  border: 2px solid #fca311;
  outline: none;
}

.Io3xNG_contact .Io3xNG_contact_dark label {
  margin-bottom: 5px;
  font-size: .9em;
  font-weight: 600;
  display: block;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 33.75em) {
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 80em) {
  .container {
    width: 75%;
    max-width: 80em;
  }
}

.row {
  width: 100%;
  position: relative;
}

.row [class^="col"] {
  float: left;
  min-height: .125rem;
  margin: .5rem 2%;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33333%;
}

.col-2-sm {
  width: 12.6667%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.3333%;
}

.col-5-sm {
  width: 37.6667%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.3333%;
}

.col-8-sm {
  width: 62.6667%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.3333%;
}

.col-11-sm {
  width: 87.6667%;
}

.col-12-sm {
  width: 96%;
}

@media only screen and (min-width: 45em) {
  .col-1 {
    width: 4.33333%;
  }

  .col-2 {
    width: 12.6667%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.3333%;
  }

  .col-5 {
    width: 37.6667%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.3333%;
  }

  .col-8 {
    width: 62.6667%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.3333%;
  }

  .col-11 {
    width: 87.6667%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

.image-gallery-icon {
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
  background-color: #0000;
  border: 0;
  outline: none;
  transition: all .3s ease-out;
  position: absolute;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }

  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}

.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button, .image-gallery-play-button {
  padding: 20px;
  bottom: 0;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}

@media (max-width: 768px) {
  .image-gallery-fullscreen-button, .image-gallery-play-button {
    padding: 15px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}

@media (max-width: 480px) {
  .image-gallery-fullscreen-button, .image-gallery-play-button {
    padding: 10px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}

@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}

@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}

.image-gallery-left-nav[disabled], .image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -webkit-tap-highlight-color: #0000;
  position: relative;
}

.image-gallery.fullscreen-modal {
  height: 100%;
  width: 100%;
  z-index: 5;
  background: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  line-height: 0;
  position: relative;
  top: 0;
}

.image-gallery-content.fullscreen {
  background: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  width: calc(100% - 110px);
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  white-space: nowrap;
  text-align: center;
  line-height: 0;
  position: relative;
  overflow: hidden;
}

.image-gallery-slide {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-gallery-slide.center {
  position: relative;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}

.image-gallery-slide .image-gallery-description {
  color: #fff;
  white-space: normal;
  background: #0006;
  padding: 10px 20px;
  line-height: 1;
  position: absolute;
  bottom: 70px;
  left: 0;
}

@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    padding: 8px 15px;
    font-size: .8em;
    bottom: 45px;
  }
}

.image-gallery-bullets {
  width: 80%;
  z-index: 4;
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.image-gallery-bullets .image-gallery-bullets-container {
  text-align: center;
  margin: 0;
  padding: 0;
}

.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 50%;
  outline: none;
  margin: 0 5px;
  padding: 5px;
  transition: all .2s ease-out;
  display: inline-block;
  box-shadow: 0 2px 2px #1a1a1a;
}

@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}

@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}

.image-gallery-bullets .image-gallery-bullet:focus {
  background: #337ab7;
  border: 1px solid #337ab7;
  transform: scale(1.2);
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
  border: 1px solid #fff;
  transform: scale(1.2);
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }

  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  vertical-align: top;
  width: 100px;
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  margin-right: 0;
  padding: 0;
  display: block;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-top: 2px;
  margin-left: 0;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  padding: 5px 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  width: 100px;
  background: none;
  border: 4px solid #0000;
  padding: 0;
  transition: border .3s ease-out;
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail {
    width: 81px;
    border: 3px solid #0000;
  }
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 4px solid #337ab7;
  outline: none;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    border: 4px solid #337ab7;
    outline: none;
  }
}

@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px 2px #1a1a1a;
  white-space: normal;
  width: 100%;
  padding: 5%;
  font-size: 1em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  color: #fff;
  z-index: 4;
  background: #0006;
  padding: 10px 20px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .image-gallery-index {
    padding: 5px 10px;
    font-size: .8em;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.B2wDDG_app {
  font-size: 1em;
}

h1 {
  color: #5a3a9c;
  font-size: 2em;
}

h1 + h2 {
  margin-top: 0;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

.B2wDDG_tm_content {
  min-height: calc(100vh - 220px);
}

@media only screen and (max-width: 800px) {
  h2 {
    font-size: 2.2em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.2em;
  }
}

a:link, a:visited {
  color: #5a3a9c;
  border-bottom: 2px solid #fca31100;
  text-decoration: none;
  transition: all .2s ease-in;
}

a:link:hover, a:visited:hover {
  border-bottom: 2px solid #fca311;
}

.zH68fW_btn, a.zH68fW_btn {
  color: #090615;
  cursor: pointer;
  background: #fca311;
  border: none;
  padding: 20px 40px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.zH68fW_btn:hover, a.zH68fW_btn:hover {
  color: #fff;
  background: #5a3a9c;
  border: none;
  box-shadow: 5px 5px #090615;
}

@media only screen and (max-width: 800px) {
  .zH68fW_btn, a.zH68fW_btn {
    text-align: center;
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    display: block;
  }
}

.zH68fW_pill {
  background: #fca311;
  border-radius: 20px;
  margin: 3px 0;
  padding: 6px 10px;
  font-size: .8em;
  display: inline-block;
}

.zH68fW_pill:not(:last-child) {
  margin-right: 5px;
}

.zH68fW_footer {
  min-height: 220px;
  color: #fff;
  background: #090615;
  flex-direction: column;
  justify-content: end;
  display: flex;
}

.zH68fW_footer .zH68fW_content {
  min-height: 160px;
}

@media only screen and (max-width: 900px) {
  .zH68fW_footer .zH68fW_content {
    margin: 20px 0;
  }
}

.zH68fW_footer .zH68fW_social {
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  display: flex;
}

.zH68fW_footer .zH68fW_social > a {
  margin-right: 10px;
  display: inline-block;
}

.zH68fW_footer .zH68fW_social img {
  width: 35px;
  height: 35px;
}

@media only screen and (max-width: 900px) {
  .zH68fW_footer .zH68fW_social {
    padding: 30px 0;
  }
}

.zH68fW_footer nav.zH68fW_policylinks {
  justify-content: flex-end;
}

@media only screen and (min-width: 720px) and (max-width: 900px) {
  .zH68fW_footer nav.zH68fW_policylinks {
    align-items: end;
  }
}

.zH68fW_footer nav.zH68fW_links, .zH68fW_footer nav.zH68fW_policylinks {
  flex-flow: wrap;
  margin-top: 20px;
  padding: 0;
  display: flex;
}

.zH68fW_footer nav.zH68fW_links a, .zH68fW_footer nav.zH68fW_policylinks a {
  color: #fff;
  text-transform: uppercase;
  margin-right: 20px;
  font-weight: 700;
}

.zH68fW_footer nav.zH68fW_links a:last-child, .zH68fW_footer nav.zH68fW_policylinks a:last-child {
  margin-right: unset;
}

@media only screen and (max-width: 900px) {
  .zH68fW_footer nav.zH68fW_links, .zH68fW_footer nav.zH68fW_policylinks {
    flex-direction: column;
    margin-top: 0;
    margin-right: 0;
  }

  .zH68fW_footer nav.zH68fW_links a, .zH68fW_footer nav.zH68fW_policylinks a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: unset;
  }

  .zH68fW_footer nav.zH68fW_links a:not(:first-child), .zH68fW_footer nav.zH68fW_policylinks a:not(:first-child) {
    margin-top: 20px;
  }

  .zH68fW_footer nav.zH68fW_links a:last-child, .zH68fW_footer nav.zH68fW_policylinks a:last-child {
    margin-bottom: 20px;
  }
}

.zH68fW_footer .zH68fW_copyright {
  height: 40px;
  justify-content: right;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 720px) and (max-width: 900px) {
  .zH68fW_footer .zH68fW_copyright {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 719px) {
  .zH68fW_footer .zH68fW_copyright {
    justify-content: left;
  }
}

.A39vTG_loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  display: flex;
}

.A39vTG_loading .A39vTG_text {
  display: block;
}

/*# sourceMappingURL=index.ec47c74b.css.map */

@import "./colours";

//Links
a:link, a:visited {
    color: $purple;
    text-decoration: none;
    transition:all 0.2s ease-in;
    border-bottom: solid 2px transparentize($orange, 1%);

    &:hover {
        border-bottom: solid 2px $orange;
    }
}

//Buttons

.btn, a.btn {
    padding: 20px 40px;
    background: $orange;
    color: $black;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2em;
    transition:all 0.5s;
    border: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
        background: $purple;
        color: #FFF;
        -webkit-box-shadow: 5px 5px 0px 0px $black;
        -moz-box-shadow: 5px 5px 0px 0px $black;
        box-shadow: 5px 5px 0px 0px $black;
        border: none;
    }

    @media only screen and (max-width: 800px) {
        display: block;
        text-align: center;
        margin: 10px;
        display: block;
        width: calc(100% - 20px);
        box-sizing: border-box;
    }
}

.pill {
    background: $orange;
    border-radius: 20px;
    font-size: 0.8em;
    padding: 6px 10px;
    display: inline-block;
    margin: 3px 0;

    &:not(:last-child) {
        margin-right: 5px;
    }
}
